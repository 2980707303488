import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react"
import "./AssetDetails.scss"
import _ from "lodash"
import {
  faEye,
  faPen,
  faTrashAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { constants } from "../../../store/constants"
import Table from "../../../components/Table/Table"
import Button from "../../../components/Button/Button"
import Modal from "../../../components/Modal/Modal"

const CampaignDetails = ({
  campaignsStore,
  paid,
  backButton,
  openCampaignCreateEditModal,
  changeOngoingFieldDisplay,
}) => {
  const [state, setState] = useState({
    // eslint-disable-next-line react/destructuring-assignment
    paid,
    campaignData: [],
    campaignCount: null,
    campaignOverview: {},
    latestCampaignValue: {},
  })

  const getCampaignDataForState = () => {
    const campaignCount = campaignsStore.campaignPage?.data ? campaignsStore.campaignPage?.total : 0
    const campaignData = campaignsStore.campaignPage?.data || []
    return { campaignCount, campaignData }
  }

  useEffect(() => {
    const init = async () => {
      const fetchData = async () => {
        await campaignsStore.getCampaigns({ paid })

        const { campaignCount, campaignData } = getCampaignDataForState()

        const limit = 20
        const offset = 0

        const pageData = {
          limit,
          offset,
          paid: state.paid,
          sort: "",
          total: campaignCount
        }

        setState(state => ({
          ...state,
          pageData,
          campaignData
        }))
      }

      fetchData()
    }
    init()
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openCampaignOverviewModal = async (currentCampaign) => {
    const creationTime = new Date(currentCampaign.createdAt)
    const updatedTime = new Date(currentCampaign.updatedAt)
    setState(state => ({
      ...state,
      showCampaignOverviewModal: true,
      campaignOverview: {
        ...currentCampaign,
        createdAt: creationTime.toLocaleTimeString('en-US'),
        createdOn: creationTime.toLocaleDateString('en-US'),
        updatedAt: currentCampaign.updatedAt ? updatedTime.toLocaleTimeString('en-US') : '',
        updatedOn: currentCampaign.updatedAt ? updatedTime.toLocaleDateString('en-US') : '',
      },
    }))
  }

  const dismissCampaignOverviewModal = () => {
    // eslint-disable-next-line
    setState(state => ({ ...state, showCampaignOverviewModal: false }))
  }

  const confirmCampaignDelete = async (currentCampaign) => {
    setState(state => ({
      ...state,
      showCampaignDeleteConfirmModal: true,
      latestCampaignValue: { ...currentCampaign, onGoing: currentCampaign.onGoing === 1 ? true : false },
    }))
  }

  const deleteCampaign = async () => {
    await campaignsStore.deleteCampaign(state.latestCampaignValue)
    await campaignsStore.getCampaigns({ paid })
    const { campaignCount, campaignData } = getCampaignDataForState()

    const campaignDataForDisplay = await changeOngoingFieldDisplay(campaignData)
    setState(state => ({
      ...state,
      campaignCount,
      campaignData: campaignDataForDisplay,
      showCampaignDeleteConfirmModal: false,
    }))
  }

  const campaignTableHeaders = [{
    label: '#',
    key: 'id',
    styles: {
      width: '50px',
      justifyContent: 'center',
    },
  }, {
    label: 'Project App ID',
    key: 'paid',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Campaign ID',
    key: 'campaignId',
    styles: {
      flex: 2,
    },
  }, {
    label: 'Name',
    key: 'name',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Ongoing',
    key: 'onGoing',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Status',
    key: 'status',
    styles: {
      flex: 1,
    },
  }]

  const goBack = () => {
    backButton()
  }

  const handlePageChange = (query) => {
    campaignsStore.getCampaigns({ ...query, paid })
  }

  return (
    <>
      <div className="page-content">
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button
            dataTip="Back To Dashboard"
            icon={faArrowLeft}
            variant="primary"
            onClick={goBack}
            customStyle={{ marginRight: 50 }}
          />
          <div style={{ color: '#425E99' }}>
            CAMPAIGNS FOR PAID: &nbsp;
            {paid}
          </div>
        </div>
        <Table
          headers={campaignTableHeaders}
          page={{
            ...campaignsStore.campaignPage,
            data: campaignsStore.campaignPage.data,
          }}
          onPageChange={handlePageChange}
        >
          <Button
            dataTip="Overview"
            variant="primary"
            icon={faEye}
            onClick={openCampaignOverviewModal}
          />
          <Button
            dataTip="Edit"
            variant="primary"
            icon={faPen}
            onClick={openCampaignCreateEditModal}
          />
          <Button
            dataTip="Delete"
            variant="error"
            icon={faTrashAlt}
            onClick={confirmCampaignDelete}
            hidden={(self) => (self.status === 'RUNNING')}
          />
          <ReactTooltip />
        </Table>
      </div>

      <Modal
        show={state.showCampaignOverviewModal}
        onDismiss={dismissCampaignOverviewModal}
        header="Campaign Details"
        width="30%"
        noSubmit
      >
        <div style={{ marginLeft: '1rem' }}>
          <p>
            <span style={{ fontWeight: 'bold' }}>Id :</span>
            {state.campaignOverview.id}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Campaign Id :</span>
            {state.campaignOverview.campaignId}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Project App ID :</span>
            {state.campaignOverview.paid}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Campaign Name :</span>
            {state.campaignOverview.name}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Ongoing :</span>
            {state.campaignOverview.onGoing}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Status :</span>
            {state.campaignOverview.status}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Created On :</span>
            {state.campaignOverview.createdOn}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Created At :</span>
            {state.campaignOverview.createdAt}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Report :</span>
            {state.campaignOverview.report}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Latest :</span>
            {state.campaignOverview.latest}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Report Last Updated :</span>
            {state.campaignOverview.reportLastUpdated}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>List Ids :</span>
            {state.campaignOverview.listIds}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Updated By :</span>
            {state.campaignOverview.updateBy}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Updated On :</span>
            {state.campaignOverview.updatedOn}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Updated At :</span>
            {state.campaignOverview.updatedAt}
          </p>
        </div>
      </Modal>

      <Modal
        show={state.showCampaignDeleteConfirmModal}
        onDismiss={() => { setState(state => ({ ...state, showCampaignDeleteConfirmModal: false })) }}
        onSubmit={deleteCampaign}
        submitButtonVariant="error"
        submitLabel="Delete"
        header="Confirm Delete"
        width="30%"
      >
        <div>
          Are you sure you want to remove the campaign &nbsp;
          <span style={{ fontWeight: 'bold' }}>{state.latestCampaignValue.name}</span>
          ?
        </div>
      </Modal>
    </>
  )
}

export default inject(
  constants.campaignsStore,
)(observer(CampaignDetails))
