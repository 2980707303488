import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { constants } from '../../../store/constants'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import FormDropdown from '../../../components/Form/FormDropdown'

const CampaignCreateEditForm = ({
  campaignsStore,
  notificationStore,
  paid,
  isDisplayed,
  currentCampaign,
  closeCampaignCreateEditModal,
  setCampaignsData
}) => {

  const [state, setState] = useState({
    originalCampaignValue: {},
    latestCampaignValue: {},
    campaignFormError: [],
  })

  useEffect(() => {
    if (!isDisplayed) {
      return
    }
    let campaign = {}
    if (!currentCampaign.id) {
      campaign = {
        name: '',
        onGoing: true,
        status: 'PENDING',
        paid,
      }
    } else {
      campaign = {
        id: currentCampaign.id,
        campaignId: currentCampaign.campaignId,
        name: currentCampaign.name,
        onGoing: (currentCampaign.onGoing === 1) ? true : false,
        paid: currentCampaign.paid,
        status: currentCampaign.status,
      }
    }

    setState(state => ({
      ...state,
      originalCampaignValue: campaign,
      latestCampaignValue: campaign,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayed])

  const handleInputChange = (event) => {
    const { value, name } = event.target

    let campaignFormError = []
    if (name === 'name' && (value === '' || value === undefined)) {
      campaignFormError = ['This is Required']
    }

    setState(state => ({
      ...state,
      campaignFormError,
      latestCampaignValue: {
        ...state.latestCampaignValue,
        [name]: value,
      },
    }))
  }

  const dismissCampaignFormModal = () => {
    // eslint-disable-next-line
    if (_.isEqual(state.originalCampaignValue, state.latestCampaignValue) || confirm('All unsaved changes will be discarded')) {
      setState(state => ({
        ...state, latestCampaignValue: {}, originalCampaignValue: {}, campaignFormError: [],
      }))
      closeCampaignCreateEditModal()
    }
  }

  const saveCampaignForm = async () => {
    const { id } = state.latestCampaignValue
    if (!state.latestCampaignValue.name || state.latestCampaignValue.name === '') {
      setState(state => ({
        ...state, campaignFormError: ['This is Required']
      }))
      notificationStore.error({
        message: 'Campaign Name is Missing!',
      })
      return
    }
    if (state.campaignFormError.length) {
      notificationStore.error({
        message: 'Campaign Name is Missing!',
      })
      return
    }
    if (id) {
      await campaignsStore.updateCampaign(state.latestCampaignValue)
    } else {
      await campaignsStore.createCampaign(state.latestCampaignValue)
    }
    setState(state => ({
      ...state, latestCampaignValue: {}, originalCampaignValue: {},
    }))
    await setCampaignsData()
    await closeCampaignCreateEditModal()
  }

  return (
    <Modal
      show={isDisplayed}
      onDismiss={dismissCampaignFormModal}
      onSubmit={saveCampaignForm}
      header="Add/Update Campaign"
      width="30%"
    >
      <Form>
        <div>
          <FormInput
            name="paid"
            label="Project App ID"
            type="text"
            value={state.latestCampaignValue.paid}
            disabled
          />
          <FormInput
            name="name"
            label="Campaign Name"
            type="text"
            value={state.latestCampaignValue.name}
            errors={state.campaignFormError}
            onChange={handleInputChange}
            required
            maxLength={255}
          />
          <FormDropdown
            name="status"
            label="Status"
            options={['PENDING', 'RUNNING', 'COMPLETED'].map((item) => ({
              value: item,
              label: item,
            }))}
            value={state.latestCampaignValue.status}
            onChange={handleInputChange}
            disabled={state.latestCampaignValue.id ? false : true}
          />
          <FormDropdown
            name="onGoing"
            label="On Going"
            options={['YES', 'NO'].map((item) => ({
              value: item === 'YES' ? true : false,
              label: item,
            }))}
            value={state.latestCampaignValue.onGoing}
            onChange={handleInputChange}
            disabled={state.latestCampaignValue.id ? false : true}
          />
        </div>
      </Form>
    </Modal>
  )
}
export default inject(
  constants.campaignsStore,
  constants.notificationStore,
)(observer(CampaignCreateEditForm))
