/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import './AssetDetails.scss'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import { constants } from '../../../store/constants'
import PageTitle from '../../../components/PageTitle/PageTitle'
import TemplatesDetails from './TemplatesDetails'
import MasterTemplatesDetails from './MasterTemplateDetails'
import CampaignDetails from './CampaignDetails'
import CampaignCreateEditForm from './CampaignCreateEditForm'

const NEW_PROJECT_APP = {
  applicationId: '',
}

const AssetDetails = ({
  tokensStore,
  applicationsStore,
  match,
  companiesStore,
  divisionsStore,
  projectsStore,
  campaignsStore,
  templatesStore,
  masterTemplateStore,
}) => {
  const [state, setState] = useState({
    selectedApplication: { ...NEW_PROJECT_APP },
    permissions: {},
    breadcrumbs: [],

    // CAMPAIGN DATA
    campaignCount: null,
    campaignData: [],
    currentCampaign: {},
    showCampaignTable: false,
    showCampaignCreateEditModal: false,

    // TEMPLATE RELATED
    templateCount: null,
    showTemplatesTable: false,

    masterTemplateCount: null,
    showMasterTemplatesTable: false,
  })

  const getCampaignDataForState = () => {
    const campaignCount = campaignsStore.campaignPage?.data ? campaignsStore.campaignPage?.total : 0
    const campaignData = campaignsStore.campaignPage?.data || []
    return { campaignCount, campaignData }
  }
  const getDashboardData = async () => {
    await campaignsStore.getCampaigns({ paid: match.params.paId })
    const { campaignCount, campaignData } = getCampaignDataForState()
    await templatesStore.getTemplates({ paid: match.params.paId })
    const templateCount = templatesStore.templatePage?.data ? templatesStore.templatePage?.total : 0
    await masterTemplateStore.getMasterTemplates({ paid: match.params.paId, activeDivisionId: 1, page: 0, itemsPerPage: 9999 })
    const masterTemplateCount = masterTemplateStore.masterTemplates?.data ? masterTemplateStore.masterTemplates?.data.length : 0

    return { campaignCount, campaignData, templateCount, masterTemplateCount }
  }
  useEffect(() => {
    const init = async () => {
      const fetchData = async () => {
        await projectsStore.getProjectApps({
          projectId: match.params.projectId,
        })
        const projectAppIds = _.map(projectsStore.projectApps, 'id')

        await tokensStore.getProjectTokens({ projectAppIds })
        await applicationsStore.getApplications({ limit: 100, deleted: false })

        const { campaignCount, campaignData, templateCount, masterTemplateCount } = await getDashboardData()

        const { name: companyName } = await companiesStore.getCurrentCompany(
          match.params.companyId,
        )
        const { name: divisionName } = await divisionsStore.getCurrentDivision(
          match.params.divisionId,
        )
        const { name: projectsName } = await projectsStore.getCurrentProject(
          match.params.projectId,
        )

        const breadcrumbs = [
          {
            label: companyName,
            url: 'companies',
          },
          {
            label: divisionName,
            url: `companies/${match.params.companyId}/divisions`,
          },
          {
            label: projectsName,
            url: `companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects`,
          },
          {
            label: 'Details',
            // eslint-disable-next-line max-len
            url: `companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects/${match.params.projectId}/details`,
          },
        ]

        setState(state => ({
          ...state,
          breadcrumbs,
          campaignCount,
          campaignData,
          templateCount,
          masterTemplateCount,
        }))
      }

      fetchData()
    }
    init()
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeOngoingFieldDisplay = async (campaigns) => {
    const updated = await campaigns.map((item) => {
      let newItem = {}
      if (item.onGoing == 1) {
        newItem = { ...item, onGoing: 'Yes' }
      } else {
        newItem = { ...item, onGoing: 'No' }
      }
      return newItem
    })
    return updated
  }

  const setCampaignsData = async () => {
    await campaignsStore.getCampaigns({ paid: match.params.paId })
    const { campaignCount, campaignData } = getCampaignDataForState()

    const campaignDataForDisplay = await changeOngoingFieldDisplay(campaignData)
    const rverseSortedList = _.sortBy(campaignDataForDisplay, [(o) => o.id]).reverse()
    setState(state => ({
      ...state,
      campaignCount,
      campaignData: rverseSortedList,
    }))
  }

  const showCampaignsTable = async () => {
    setState(state => ({
      ...state,
      showCampaignTable: true,
    }))
  }

  const showTemplatesTable = () => {
    setState(state => ({
      ...state,
      showTemplatesTable: true,
    }))
  }

  const showMasterTemplatesTable = () => {
    setState(state => ({
      ...state,
      showMasterTemplatesTable: true,
    }))
  }

  const openCampaignCreateEditModal = async (currentCampaign) => {
    if (!currentCampaign) {
      setState(state => ({
        ...state,
        showCampaignCreateEditModal: true,
      }))
      return
    }
    setState(state => ({
      ...state,
      showCampaignCreateEditModal: true,
      currentCampaign,
    }))
  }

  const closeCampaignCreateEditModal = async () => {
    setState(state => ({
      ...state,
      showCampaignCreateEditModal: false,
      currentCampaign: {}
    }))  
  }

  const campaignTableBackBtn = async () => {
    await setCampaignsData()
    setState(state => ({ ...state, showCampaignTable: false }))
  }

  return (
    <>
      <PageTitle title={`Assets (Project App ID: ${match.params.paId})`} breadcrumbs={state.breadcrumbs} />

      {!(state.showCampaignTable || state.showTemplatesTable || state.showMasterTemplatesTable) ? (
        <div className="box-container">
          <div className="banner-box campaign">
            <div className="index-number">{state.campaignCount}</div>
            <div className="index-text">Campaigns</div>
            <div className="controls">
              <span className="control-button" style={{ marginLeft: '1rem' }} onClick={() => openCampaignCreateEditModal(false)}>New</span>
              <span className="control-button" style={{ marginRight: '1rem' }} onClick={showCampaignsTable}>View</span>
            </div>
          </div>

          <div className="banner-box template">
            <div className="index-number">{state.templateCount}</div>
            <div className="index-text">Templates</div>
            <div className="controls">
              <span className="control-button" style={{ marginleft: '1rem' }} />
              <span className="control-button" style={{ marginRight: '1rem' }} onClick={showTemplatesTable}>View</span>
            </div>
          </div>

          <div className="banner-box template">
            <div className="index-number">{state.masterTemplateCount}</div>
            <div className="index-text">Master Templates</div>
            <div className="controls">
              <span className="control-button" style={{ marginleft: '1rem' }} />
              <span className="control-button" style={{ marginRight: '1rem' }} onClick={showMasterTemplatesTable}>View</span>
            </div>
          </div>
        </div>
      ) : ('')}

      {state.showCampaignTable ? (
        // eslint-disable-next-line max-len
        <CampaignDetails paid={match.params.paId} backButton={campaignTableBackBtn} openCampaignCreateEditModal={openCampaignCreateEditModal} changeOngoingFieldDisplay={changeOngoingFieldDisplay} />
      ) : ('')}

      {state.showTemplatesTable ? (
        // eslint-disable-next-line max-len
        <TemplatesDetails paid={match.params.paId} backButton={() => { setState(state => ({ ...state, showTemplatesTable: false })) }} />
      ) : ('')}

      {state.showMasterTemplatesTable ? (
        // eslint-disable-next-line max-len
        <MasterTemplatesDetails paid={match.params.paId} backButton={() => { setState(state => ({ ...state, showMasterTemplatesTable: false })) }} />
      ) : ('')}

      <CampaignCreateEditForm
        paid={match.params.paId}
        isDisplayed={state.showCampaignCreateEditModal}
        currentCampaign={state.currentCampaign}
        closeCampaignCreateEditModal={closeCampaignCreateEditModal}
        setCampaignsData={setCampaignsData}
      />
    </>
  )
}

AssetDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      divisionId: PropTypes.string,
      companyId: PropTypes.string,
      projectId: PropTypes.string,
      paId: PropTypes.string,
    }),
  }).isRequired,
}

export default inject(
  constants.divisionsStore,
  constants.companiesStore,
  constants.projectsStore,
  constants.tokensStore,
  constants.applicationsStore,
  constants.vendorsStore,
  constants.voicesStore,
  constants.voiceAssetsStore,
  constants.billingsStore,
  constants.campaignsStore,
  constants.templatesStore,
  constants.masterTemplateStore,
)(observer(AssetDetails))
